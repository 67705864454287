
export default {
	name: 'officeList',
	data() {
		return {
			list: '',
			search: this.$store.state.result,
			searchid: this.$store.state.id,
			total: 0,
			pageSize: 0,
			currentPage: 1
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getOfficeList()
	},
	methods: {
		getOfficeList() {
			//				console.log(this.search)
			var _this = this;
			_this.$http.post('/API/PLAT/inPageA', {
				name: _this.search,
				smoId: _this.searchid,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getOfficeList()
		},
		//搜索按钮
		searchBtn() {
			var _this = this;
			_this.$http.post('/API/PLAT/inPageA', {
				name: _this.search,
				page: 1
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
				}
			})
			_this.getOfficeList()
		},
		//跳转CRC详情
		toPar(id) {
			this.$router.push({ name: 'crcParticulars', params: { id: id } });
			this.$store.commit('getId', id)
		},
		//面包屑
		toCrcMena() {
			this.$router.push('/statistics')
		},
		//导出
		export2Excel() {
			var _this = this;
			if (_this.list) {
				_this.$http.post('/API/PLAT/inPageAExcel', {
					name: _this.search,
					smoId: _this.searchid
				}, {
					headers: { "token": _this.token }
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['CRC', 'SMO', '年龄', '学历', '专业', '从业年限', '项目数量'];
						const filterVal = ['crcName', 'enterpriseName', 'years', 'education', 'major', 'age', 'psize'];
						const data = _this.formatJson(filterVal, response.data.data.objArray);
						export_json_to_excel(tHeader, data, '科室人员详情列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
